













































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { massSet } from '@/services/massage-service';
import { WechatSendMessageGroupService } from '@/services/wechat-send-messages-group-service';
@Component({
  components: {

  }
})
export default class searchPerson extends Vue {
  @Prop({ default: true }) private query!: String;
  @Prop({ default: true }) private sendArr!: any;
  public form: any = {
    pages: 1,
    size: 5,
    current: 1
  };
  private tableData: any[] = [];     
  private applications: any[] = [];
  private groupArr: any[] = [];
  private currentRow: any = '';
  public loading: Boolean = true;
  @Inject(massSet) private massSet!: massSet; 
  @Inject(WechatSendMessageGroupService) private wechatSendMessageGroupService!: WechatSendMessageGroupService;
  public async created(): Promise<void> {
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    this.form.channelId =  this.$route.query.channelId;
    console.log('this.sendArr',this.sendArr)
    if (this.sendArr.length > 0) {
      for (var i = 0; i < this.sendArr.length; i++) {
        this.groupArr.push(this.sendArr[i])
      }
      this.$set(this.form, 'groupSetIds', '')
    } else {
      await this.sendMessageListAll()
    }
    await this.getList()
    loading.close();
  }
  private mounted() {
  }
  // 重置
  public reset() {
    this.form = {
      pages: 1,
      size: 5,
      current: 1
    }
    this.search()
  }
  //获取指定分组
  public async sendMessageListAll(): Promise<void> {
    const res = await this.wechatSendMessageGroupService.sendMessageListAll(this.query);
    this.groupArr = res;
    this.$set(this.form.groupSetIds, 'groupSetIds', '')

  }
  // 搜索
  public search() {
    this.getList()
  }
  // 全选
  public async chooseAll() {
    if (this.tableData.length > 0) {
      const res = await this.massSet.suretPersonList(this.form);
      this.close(res);
    } else {
      this.$message({
        message: "当前条件下无人员，请确认",
        type: 'error'
      });
    }

  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    this.form.pageSize = Number(this.form.size);
    this.form.pageNum = Number(this.form.current);
    this.form.channelId = this.$route.query.channelId;
    const res = await this.massSet.getPersonList(this.form);
    this.tableData = res.records;
    this.form.total = res.total;
    this.form.pages = res.pages;
    this.form.size = res.size;
    this.form.current = res.current;
    this.loading = false;
  }
  @Emit('close')
  public close(val: any): Boolean {
    return val
  }
}
