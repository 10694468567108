import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class WechatSendMessageGroupService {
  @Inject(HttpService) private http!: HttpService;

    //群发消息分组获取分组
    public sendMessageListAll(param: Dict<any>): Promise<any> {
      return this.http.get('/api/application/sendMessagesGroupSet/listAll',param);
    }
    // 应用/专栏错误日志下载
    public downloadSingle(param: Dict<any>): Promise<any> {
      return this.http.get('/api/application/sendMessagesGroup/errorUsers',param);
    }
}
